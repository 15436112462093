.SMSDetailsPage {
  padding: 1.5rem;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  min-width: 700px;
  margin-bottom: 0.5rem;

  .sale-details-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    width: 100%;

    .sms-title {
      font-weight: 500;
      font-size: 38px;
      margin: 0 0 0 2rem;
    }

    .sms-tag {
      margin-right: 0px;
      margin-left: auto;
    }
  }

  .sale-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .info-title {
      margin: 0;
      font-size: 12px;
    }

    .info-description {
      font-weight: 700;
      font-size: 16px;
    }

    .individual-info-column {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        margin-top: 0px;
      }
    }
  }

  .info-table {
    border-radius: 8px;
    overflow: hidden;

    thead[class*="ant-table-thead"] th {
      color: #ffffff;
      background-color: #09415c !important;
    }

    .ant-table-content {
      .ant-table-thead {
        .ant-table-cell {
          font-weight: 500;
        }

        tr {
          th {
            &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
              @media (min-width: 576px) {
                background-color: transparent;
              }
            }
          }
        }
      }

      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 6px 8px;
          }
        }
      }
    }
  }

  .info-table tr:nth-child(2n) td {
    background-color: rgba(9, 65, 92, 0.1);
  }
}