.main-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 0;
  overflow-y: scroll;

  &-automic {
    height: calc(100vh - 64px);
    background-color: #ffffff;
  }
}

.sms-call-number {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  line-height: 0;
  align-items: flex-end;
  justify-content: center;
  .sms-number {
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
  }
  .sms-text {
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 400;
  }
}
