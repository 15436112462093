.FileUploader {
  position: relative;
  .file-uploader-title {
    font-size: 24px;
    font-weight: 700;
  }
  .ant-upload.ant-upload-select {
    position: absolute;
    right: 0;
  }
  .upload-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
