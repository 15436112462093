html,
body {
  margin: 0px;
  font-size: 16px;
  height: auto;
  font-weight: 300;
  font-family: "Helvetica", sans-serif;
  font-size: 16px;
  line-height: 1.5715;
}

* {
  font-family: "Helvetica", sans-serif;
}

h2 {
  margin: 16px 0 12px 0;
}