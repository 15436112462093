.sms-login-page {
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  .sms-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 420px;
    margin: 1rem;
  }
  .sms-form-wrapper {
    padding: 2rem;
    border-radius: 16px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    .sms_sale_login-form {
      .ant-row {
        display: block;
      }

      .ant-alert-message {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
}
