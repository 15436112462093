.SMSDetailsPageMobile {
  padding: 0.8rem;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  margin-bottom: 0.5rem;
  overflow: scroll;
  position: relative;

  .sale-details-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0.3rem;
    width: 100%;

    .sms-title {
      font-size: 18px;
      margin: 0 0 0 0.5rem;
      font-weight: 500;
    }

    .sms-tag {
      margin-right: 0px;
      margin-left: auto;
    }
  }

  .sale-details-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;

    .info-title {
      margin: 0;
      font-size: 12px;
    }

    .info-description {
      font-weight: 700;
      font-size: 16px;
    }

    .individual-info-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        margin-top: 0px;
      }
    }

  }

  .panel-container {
    display: flex;
    justify-content: space-between;
  }

  .info-table tr:nth-child(2n) td {
    background-color: rgba(9, 65, 92, 0.1);
  }

  h2 {
    margin-bottom: 10px;
  }
}
